import React from "react";
import {Button, Modal} from "react-bootstrap";

const BaseModal = ({isShow, title, component, handleClose, handleSave}) => {

    return <>
        <Modal show={isShow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {component && component()}
            </Modal.Body>
            <Modal.Footer>
                {handleSave &&
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    </>;
}

export default BaseModal;