export const LeadsListTypes = {
  SET_LEADS: 'leads-list/SET_ITEMS',
  SET_BULK_ACTION: 'leads-list/SET_BULK_ACTION',
  SET_BULK_STATUS: 'leads-list/SET_BULK_STATUS',
  SET_BULK_COMPONENT: 'leads-list/SET_BULK_COMPONENT',
  SET_CURRENT_PAGE: 'leads-list/SET_CURRENT_PAGE',
  SET_PER_PAGE: 'leads-list/SET_PER_PAGE',
  SET_LEAD: 'leads-list/SET_LEAD',
  SET_SEARCH: 'leads-list/SET_SEARCH',
  SET_FILTER: 'leads-list/SET_FILTER',
  HIDE_BULK_MODAL: 'leads-list/HIDE_BULK_MODAL',
  HIDE_EDIT_MODAL: 'leads-list/HIDE_EDIT_MODAL',
  SET_SELECTED_ITEMS: 'leads-list/SET_SELECTED_ITEMS',
  REMOVE_SELECTED_ITEMS: 'leads-list/REMOVE_SELECTED_ITEMS',
  CHANGE_LEADS_STATUS: 'leads-list/CHANGE_LEADS_STATUS'
};

export const LeadStatuses = {
  hold: 'hold',
  failed: 'failed',
  success: 'success'
};

export const LeadSelectStatuses = [
  {value: 'hold', label: 'Hold'},
  {value: 'failed', label: 'Failed'},
  {value: 'success', label: 'Success'}
];