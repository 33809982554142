import React from 'react';
import BaseModal from "./base-modal";
import {useDispatch, useSelector} from "react-redux";
import {getBulkComponentSelector, getBulkIsShowModalSelector} from "../../../store/selectors/leads-selectors";
import {hideBulkModalThunk} from "../../../store/thunks/leads-thunk";

const BulkActionModal = () => {
    const dispatch = useDispatch();

    let isShow = useSelector(getBulkIsShowModalSelector);
    const BulkComponent = useSelector(getBulkComponentSelector);

    const handleClose = () => {
        dispatch(hideBulkModalThunk());
    }

    return <>
        <BaseModal
            isShow={isShow}
            title={'Выберите статус'}
            component={BulkComponent}
            handleClose={handleClose}
        />
    </>;
}

export default BulkActionModal;