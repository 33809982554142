import {AppTypes} from "../constants/app-constant";

const initialState = {
    app: {
        toggleMenu: false
    }
}

const appReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case AppTypes.SET_HIDE_MENU:
            return {
                app: {
                    toggleMenu: false
                }
            }

        case AppTypes.SET_SHOW_MENU:
            return {
                app: {
                    toggleMenu: true
                }
            }

        case AppTypes.SET_TOGGLE_MENU:
            return {
                app: {
                    toggleMenu: payload
                }
            }

        default:
            return state;
    }
}

export default appReducer;