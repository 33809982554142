import {create} from 'axios';
import {API_URL} from '../config/index';
import Cookies from "universal-cookie";

const Api = create({
    withCredentials: true,
    baseURL: API_URL,
    responseType: 'json',
});

Api.interceptors.request.use(config => {
    const cookies = new Cookies();
    const token = cookies.get('authorization');
    if (token && token.length) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

Api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {

        if (error.response.status === 401) {
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

export default Api;
