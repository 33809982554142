import { SidsListTypes } from "../constants/sids-constant";

export const setSidsAction = payload => ({
    type: SidsListTypes.SET_SIDS,
    payload
});

export const setCurrentPageAction = payload => ({
    type: SidsListTypes.SET_CURRENT_PAGE,
    payload
});

export const setPerPageAction = payload => ({
    type: SidsListTypes.SET_PER_PAGE,
    payload
});

export const setSelectedItemsAction = payload => ({
    type: SidsListTypes.SET_SELECTED_ITEMS,
    payload
});

export const setSidAction = payload => ({
    type: SidsListTypes.SET_SID,
    payload
});

export const hideEditModalAction = payload => ({
    type: SidsListTypes.HIDE_EDIT_MODAL,
    payload
});

export const hideBulkModalAction = payload => ({
    type: SidsListTypes.HIDE_BULK_MODAL,
    payload
});

export const setBulkAction = payload => ({
    type: SidsListTypes.SET_BULK_ACTION,
    payload
});

export const setBulkStatusAction = payload => ({
    type: SidsListTypes.SET_BULK_STATUS,
    payload
});

export const setIsFetchingAction = payload => ({
    type: SidsListTypes.SET_BULK_STATUS,
    payload
});


