import Api from "./api";

export const login = ({email, password }) => {

    return Api.post('/auth/login', {email, password});
}

export const logout = () => {

    return Api.post('/auth/logout');
}
