import { LeadsListTypes } from "../constants/leads-constant";

export const setLeadsAction = payload => ({
    type: LeadsListTypes.SET_LEADS,
    payload
});

export const setCurrentPageAction = payload => ({
    type: LeadsListTypes.SET_CURRENT_PAGE,
    payload
});

export const setPerPageAction = payload => ({
    type: LeadsListTypes.SET_PER_PAGE,
    payload
});

export const setFilterAction = payload => ({
    type: LeadsListTypes.SET_FILTER,
    payload
});

export const setSelectedItemsAction = payload => ({
    type: LeadsListTypes.SET_SELECTED_ITEMS,
    payload
});

export const setLeadAction = payload => ({
    type: LeadsListTypes.SET_LEAD,
    payload
});

export const setSearchAction = payload => ({
    type: LeadsListTypes.SET_SEARCH,
    payload
});

export const setBulkComponentAction = payload => ({
    type: LeadsListTypes.SET_BULK_COMPONENT,
    payload
});

export const setBulkAction = payload => ({
    type: LeadsListTypes.SET_BULK_ACTION,
    payload
});

export const setBulkStatusAction = payload => ({
    type: LeadsListTypes.SET_BULK_STATUS,
    payload
});

export const hideBulkModalAction = payload => ({
    type: LeadsListTypes.HIDE_BULK_MODAL,
    payload
});

export const hideEditModalAction = payload => ({
    type: LeadsListTypes.HIDE_EDIT_MODAL,
    payload
});
