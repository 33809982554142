import {login, logout} from "../../api/auth-api";
import {setAuthAction, setLogoutAction} from "../actions/auth-action";
import Cookies from 'universal-cookie';

export const sendAuthThunk = ({email, password}) => {
    return (dispatch) => {
        const cookies = new Cookies();

        login({
            email, password
        }).then(response => {
            dispatch(setAuthAction(response.data));
            cookies.set('authorization', response.data.access_token, { path: '/' });
        });
    }
}

export const logoutThunk = () => {
    return (dispatch) => {
        const cookies = new Cookies();

        logout()
            .then(response => {
                cookies.remove('authorization');
                dispatch(setLogoutAction());
        });
    }
}