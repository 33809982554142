import React from "react";
import * as Yup from "yup";

const EditLeadValidation = () => {
    return Yup.object({
        name: Yup.string()
            .required('Required'),
        surname: Yup.string()
            .required('Required'),
        phone: Yup.string()
            .required('Required'),
        email: Yup.string(),
        country: Yup.string(),
        landing: Yup.string()
            .required('Required'),
        url: Yup.string(),
        sid: Yup.string(),
        status: Yup.string()
            .required('Required')
    });
}

export default EditLeadValidation;