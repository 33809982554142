import React from "react";
import StatusCardSection from "./status-card-section";
import SidCapsSection from "./sid-caps-section";

const DashboardPage = () => {

    return <>
        <div className="container-fluid px-lg-4 px-xl-5">
            <StatusCardSection />

            <SidCapsSection/>
        </div>
    </>
;
}

export default DashboardPage;