import {dashboard} from "../../api/dashboard-api";
import {setDashboardAction} from "../actions/dashboard-action";

export const getDashboardThunk = () => {
    return (dispatch) => {

        dashboard()
            .then(response => {
            dispatch(setDashboardAction(response.data));
        });
    }
}
