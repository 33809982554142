import React, {Suspense} from "react";
import Header from "../../common/header/header";
import SideBar from "../../common/side-bar/side-bar";
import Loading from "./loading";

const AdminLayout = (Component) => {
    return (props) => {

        return <>
            <Header/>
            <div className="d-flex align-items-stretch">
                <SideBar/>
                <div className="page-holder bg-gray-100">
                    <Suspense fallback={Loading}>
                        <Component {...props} />
                    </Suspense>

                    <footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 text-center text-md-start text-primary">
                                    <p className="mb-2 mb-md-0">Your company © 2021</p>
                                </div>
                                <div className="col-md-6 text-center text-md-end text-gray-400">
                                    <p className="mb-0">Version 1.0.0</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    }
}

export default AdminLayout;