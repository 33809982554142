import React, {useEffect} from "react";
import PageTitle from "../../common/items/page-title";
import Pagination from "../../common/pagination/pagination";
import {useDispatch, useSelector} from "react-redux";
import {
    getBulkComponentSelector,
    getBulkIsShowModalSelector,
    getSidsPaginationSelector,
    getSidsSelector
} from "../../../store/selectors/sids-selectors";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import {setCurrentPageAction} from "../../../store/actions/sids-action";
import {getSidsThunk, hideBulkModalThunk, setCurrentPageThunk} from "../../../store/thunks/sids-thunk";
import Item from "./item";
import SidEdit from "./sid-edit-form";
import BulkAction from "./_part/bulk-action";
import BaseModal from "../../common/modals/base-modal";

const Index = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const pagination = useSelector(getSidsPaginationSelector);
    const isShow = useSelector(getBulkIsShowModalSelector);
    const BulkComponent = useSelector(getBulkComponentSelector);

    const onChangePagination = (data) => dispatch(setCurrentPageThunk(data.selected + 1));
    const handleClose = () => {
        dispatch(hideBulkModalThunk());
    }

    useEffect(() => {
        const params = qs.parse(location.search);
        const page = params.page ? params.page : 1;

        dispatch(setCurrentPageAction(page));
        dispatch(getSidsThunk());
    }, [location.search]);

    const sids = useSelector(getSidsSelector);

    return <>
        <div className="container-fluid px-lg-4 px-xl-5">
            <div className="row">
                <PageTitle title={'Sids'}/>

                <section className="mb-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                                <BulkAction/>

                                <div className="dataTable-container border-0">
                                    <table className="table table-hover align-middle dataTable-table" id="postDatatable">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <span>id</span>
                                                </th>
                                                <th>
                                                    <span>Name</span>
                                                </th>
                                                <th>
                                                    <span>Sid</span>
                                                </th>
                                                <th>
                                                    <span>Label</span>
                                                </th>
                                                <th>
                                                    <span>Limit</span>
                                                </th>
                                                <th>
                                                    <span>Time</span>
                                                </th>
                                                <th>
                                                    <span>Is self</span>
                                                </th>
                                                <th>
                                                    <span>Status</span>
                                                </th>
                                                <th>
                                                    <span>Created At</span>
                                                </th>
                                                <th>
                                                    <span>Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            { sids.map(item => (
                                                <Item key={item.id} item={item} />
                                            )) }
                                        </tbody>
                                    </table>
                                </div>

                                <Pagination
                                    currentPage={pagination.currentPage}
                                    pageCount={pagination.lastPage}
                                    perPage={pagination.perPage}
                                    total={pagination.total}
                                    handlePageClick={onChangePagination}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <SidEdit/>

                <BaseModal
                    isShow={isShow}
                    title={'Выберите статус'}
                    component={BulkComponent}
                    handleClose={handleClose}
                />
            </div>
        </div>
    </>;
}

export default Index;