import React from "react";
import Icon from "../items/Icon";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {getToggleMenuSelector} from "../../../store/selectors/app-selector";

const SideBar = () => {

    const toggleMenu = useSelector(getToggleMenuSelector);

    return <>
        <div className={`sidebar py-3 ${toggleMenu ? 'shrink show' : ''}`} id="sidebar">
            <h6 className="sidebar-heading">Main</h6>
            <ul className="list-unstyled">
                <li className="sidebar-list-item">
                    <NavLink className="sidebar-link text-muted" to={'/dashboard/'}>
                        <Icon name={'real-estate-1'} class={'me-3'}/>
                        <span className="sidebar-link-title">Dashboard</span>
                    </NavLink>
                </li>
                <li className="sidebar-list-item">
                    <NavLink className="sidebar-link text-muted" to={'/page/leads'} data-bs-target="#cmsDropdown"
                             role="button" aria-expanded="true" data-bs-toggle="collapse">
                        <Icon name={'reading-1'} class={'me-3'}/>
                        <span className="sidebar-link-title">CMS </span>
                    </NavLink>

                    <ul className="sidebar-menu list-unstyled collapse show" id="cmsDropdown">
                        <li className="sidebar-list-item">
                            <NavLink className="sidebar-link text-muted" to={'/page/leads'}>
                                Leads
                            </NavLink>
                        </li>
                        <li className="sidebar-list-item">
                            <NavLink className="sidebar-link text-muted" to={'/page/sids'}>
                                Sids
                            </NavLink>
                        </li>
                        <li className="sidebar-list-item">
                            <NavLink className="sidebar-link text-muted" to={'/page/labels'}>
                                Labels
                            </NavLink>
                        </li>
                        <li className="sidebar-list-item">
                            <NavLink className="sidebar-link text-muted" to={'/page/request-logs'}>
                                Request Logs
                            </NavLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </>
}

export default SideBar;