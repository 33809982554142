import React from "react";
import {useDispatch} from "react-redux";
import {
    setPerPageThunk
} from "../../../../store/thunks/leads-thunk";
import {showModalAction} from "../../../../store/actions/labels-action";

const BulkAction = () => {
    const dispatch = useDispatch();

    const onChangePerPage = (event) => dispatch(setPerPageThunk(event.target.value));
    const showLabelModal = () => dispatch(showModalAction());

    return <>
        <div className="dataTable-top">
            <div className="dataTable-dropdown">
                <label>
                    <select className="dataTable-selector form-select form-select-sm" onChange={onChangePerPage}>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                    </select>
                    entries per page
                </label>
            </div>
            <div className="dataTable-search">

                <button
                   // disabled={isFetching}
                   type='variant'
                   className="btn btn-primary"
                   onClick={showLabelModal}
                   title={'Добавить новую метку'}>
                    <i className="fas fa-plus"></i>
                </button>
            </div>
        </div>
    </>;
}

export default BulkAction;