import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedItemsThunk} from "../../../../store/thunks/leads-thunk";
import {getSelectedItemsSelector} from "../../../../store/selectors/leads-selectors";
import {getLabelThunk} from "../../../../store/thunks/labels-thunk";
import {showModalAction} from "../../../../store/actions/labels-action";

const Item = ({ item }) => {

    const dispatch = useDispatch();
    const selectHandle = (id) => dispatch(setSelectedItemsThunk(id));
    const selectedIdx = useSelector(getSelectedItemsSelector);

    const handleLabel = (id) => {
        dispatch(getLabelThunk(id));
    }

    return <>
        <tr>
            <td>
                <span className="form-check">
                  <input className="form-check-input"
                         type="checkbox"
                         onChange={() => selectHandle(item.id)}
                         value={item.id}
                         checked={selectedIdx.includes(item.id)}
                  />
                </span>
            </td>
            <td>
                {item.id}
            </td>
            <td>
                {item.name}
            </td>
            <td>
                <input type="color" value={item.color} disabled />
            </td>
            <td>{item.created_at}</td>
            <td>
                <i className="m-lg-1 fas fa-edit" onClick={() => handleLabel(item.id)}></i>
            </td>
        </tr>
    </>;
}

export default Item;