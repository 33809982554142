import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsShowEditModalSelector, getSidSelector} from "../../../store/selectors/sids-selectors";
import {hideEditModalThunk, updateSidThunk} from "../../../store/thunks/sids-thunk";
import {Form, Formik} from "formik";
import TextField from "../../common/form/text-field";
import SelectField from "../../common/form/select-field";
import {Button} from "react-bootstrap";
import BaseModal from "../../common/modals/base-modal";
import EditSidValidation from "../../untils/validation/edit-sid-validation";
import {SidSelectStatuses} from "../../../store/constants/sids-constant";
import {getLabelsSelector} from "../../../store/selectors/labels-selectors";

const SidEdit = () => {

    const dispatch = useDispatch();
    const sid = useSelector(getSidSelector);
    let isShow = useSelector(getIsShowEditModalSelector);
    let labels = [];

    useSelector(getLabelsSelector).map(item => {
        labels.push({value: item.id, label: item.name})
    })

    const handleClose = () => {
        dispatch(hideEditModalThunk())
    }

    const onSubmit = (values) => {
        dispatch(updateSidThunk(values));
    }

    const sidEditForm = () => {
        return <>
            <Formik initialValues={ {...sid} } validationSchema={EditSidValidation} onSubmit={onSubmit}>
                {({values, setFieldValue, errors}) => (
                    <Form>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Id"  id="id" name="id" disabled/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Name"  id="name" name="name"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Sid"  id="sid" name="sid"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Limit"  id="limit" name="limit"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="time" placeholder="Start"  id="start" name="start"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="time" placeholder="End"  id="end" name="end"/>
                        </div>
                        <div className="mb-3">
                            <SelectField type="text"
                                         placeholder="Label"
                                         id="label"
                                         name="label_id"
                                         valueParam="value"
                                         options={labels}
                            />
                        </div>
                        <div className="mb-3">
                            <SelectField type="text"
                                         placeholder="Status"
                                         id="status"
                                         name="status"
                                         valueParam="value"
                                         options={SidSelectStatuses}
                            />
                        </div>
                        <Button type="submit" variant="primary">
                            Сохранить
                        </Button>
                    </Form>
                )}
            </Formik>
        </>;
    }

    return <>
        <BaseModal
            isShow={isShow}
            title={'Изменение сида'}
            component={sidEditForm}
            handleClose={handleClose}
        />
    </>;
}

export default SidEdit;