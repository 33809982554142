import React from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";

const InfoPopover = (props) => {

    return <>
        <OverlayTrigger placement="bottom" overlay={ <Popover id="popover-basic">
                <Popover.Header as="h3">{props.title}</Popover.Header>
                <Popover.Body>
                    {props.text}
                </Popover.Body>
            </Popover>}
        >
            {({ ref, ...triggerHandler }) => (
                <i ref={ref} {...triggerHandler} className="m-lg-1 fas fa-info-circle"></i>
            )}
        </OverlayTrigger>
    </>;
}

export default InfoPopover;