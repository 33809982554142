import {setCurrentPageAction, showModalAction} from "../actions/labels-action";
import {toast} from "react-toastify";
import {createOrUpdateLabel, getLabel, getLabels} from "../../api/labels-api";
import {hideModalAction, setLabelAction, setLabelsAction, setPerPageAction} from "../actions/labels-action";

export const getLabelsThunk = () => {
    return (dispatch, state) => {
        let labels = state().labelsReducer;
        let filter = labels.filter;

        getLabels({
            'page':  labels.pagination.currentPage,
            'perPage':  labels.pagination.perPage,
            ...filter
        }).then(response => {
            dispatch(setLabelsAction(response.data));
        });
    }
}

export const setCurrentPageThunk = (page) => {
    return (dispatch, state) => {

        if (state().labelsReducer.pagination.currentPage !== page) {
            dispatch(setCurrentPageAction(page));
            dispatch(getLabelsThunk());
        }
    }
}

export const setPerPageThunk = (perPage) => {
    return (dispatch) => {
        dispatch(setPerPageAction(perPage));
        dispatch(getLabelsThunk());
    }
}

export const hideEditModalThunk = () => {
    return (dispatch) => {
        dispatch(hideModalAction());
    }
}

export const createOrUpdateLabelThunk = (label) => {
    return (dispatch) => {
        createOrUpdateLabel(label)
            .then(() => {
                dispatch(getLabelsThunk());
                dispatch(hideEditModalThunk())
                toast.success('Все в огнях!');
            })
            .catch(() => toast.error('Что-то пошло не так!'))
    }
}

export const getLabelThunk = (id) => {
    return (dispatch) => {
        getLabel(id)
            .then(response => {
                console.log(response)
                dispatch(setLabelAction(response.data.data));
                dispatch(showModalAction());
            })
            .catch(error => {
                console.log(error)
                toast.error('Ошибка при получении метки')
            })
    }
}
