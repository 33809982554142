import { RequestLogsTypes } from "../constants/request-logs-constant";

export const setRequestLogsAction = payload => ({
    type: RequestLogsTypes.SET_REQUEST_LOGS,
    payload
});

export const setRequestLogAction = payload => ({
    type: RequestLogsTypes.SET_REQUEST_LOG,
    payload
});

export const setCurrentPageAction = payload => ({
    type: RequestLogsTypes.SET_CURRENT_PAGE,
    payload
});

export const setPerPageAction = payload => ({
    type: RequestLogsTypes.SET_PER_PAGE,
    payload
});

export const setSelectedAction = payload => ({
    type: RequestLogsTypes.SET_SELECTED,
    payload
});

