import {
    changeSidsStatus,
    getSid,
    getSids,
    refreshSids,
    removeSids,
    updateBooleanField,
    updateSid
} from "../../api/sids-api";
import {
    hideBulkModalAction,
    hideEditModalAction, setBulkAction, setBulkStatusAction,
    setCurrentPageAction, setIsFetchingAction,
    setPerPageAction,
    setSelectedItemsAction,
    setSidAction,
    setSidsAction
} from "../actions/sids-action";
import {toast} from "react-toastify";

export const getSidsThunk = () => {
    return (dispatch, state) => {
        let sids = state().sidsReducer;

        getSids({
            'page':  sids.pagination.currentPage,
            'perPage':  sids.pagination.perPage,
        }).then(response => {
            dispatch(setSidsAction(response.data));
        });
    }
}

export const getSidThunk = (id) => {
    return (dispatch) => {
        getSid(id)
            .then(response => {
                dispatch(setSidAction(response.data.data));
            })
            .catch(error => toast.error('Ошибка при получении сида'))
    }
}

export const setCurrentPageThunk = (page) => {
    return (dispatch, state) => {

        if (state().sidsReducer.pagination.currentPage !== page) {
            dispatch(setCurrentPageAction(page));
            dispatch(getSidsThunk());
        }
    }
}

export const setPerPageThunk = (perPage) => {
    return (dispatch) => {
        dispatch(setPerPageAction(perPage));
        dispatch(getSidsThunk());
    }
}

export const hideBulkModalThunk = () => {
    return (dispatch) => {
        dispatch(hideBulkModalAction());
    }
}

// Add the selected one or remove it if it is present
export const setSelectedItemsThunk = (id) => {
    return (dispatch, state) => {
        const selectedItems = state().sidsReducer.selected;

        if ( !selectedItems.includes(id) ) {
            selectedItems.push(id);
        } else {
            let index = selectedItems.indexOf(id);
            selectedItems.splice(index, 1);
        }

        dispatch(setSelectedItemsAction(selectedItems));
    }
}

export const updateBooleanFieldThunk = (id, field, value) => {
    return (dispatch) => {
        updateBooleanField(id, field, value)
            .then(() => {
                dispatch(getSidsThunk());
        })
    }
}

export const setBulkActionThunk = (action) => {
    return (dispatch) => {
        dispatch(setBulkAction(action));
    }
}

export const refreshSidsThunk = () => {
    return (dispatch) => {
        dispatch(setIsFetchingAction(true));
        refreshSids()
            .then(() => {
                dispatch(getSidsThunk());
                dispatch(setIsFetchingAction(false));
                toast.success('Список сидов обновлен!');
            })
            .catch(() => {
                dispatch(setIsFetchingAction(false));
                toast.error('Ошибка при обновлении списка')
            })
    }
}

export const setBulkStatusThunk = (status) => {
    debugger;
    console.log('status:' + status)
    return (dispatch) => {
        dispatch(setBulkStatusAction(status));
    }
}

export const hideEditModalThunk = () => {
    return (dispatch) => {
        dispatch(hideEditModalAction());
    }
}

export const removeSelectedItemsThunk = () => {
    return (dispatch, state) => {
        const ids = state().sidsReducer.selected;

        removeSids(ids).then(() => {
            dispatch(getSidsThunk());
            dispatch(setSelectedItemsAction([]));
        });
    }
}

export const changeSidsStatusThunk = () => {
    return (dispatch, state) => {
        const ids = state().sidsReducer.selected;
        const status = state().sidsReducer.bulk.status;

        changeSidsStatus({ids, status}).then(() => {
            dispatch(setSelectedItemsAction([]));
            dispatch(getSidsThunk());
            dispatch(setBulkActionThunk('undefined'));
            toast.success('Статус сидам обновлен!');
        });
    }
}

export const updateSidThunk = (sid) => {
    return (dispatch) => {
        updateSid(sid)
            .then(() => {
                dispatch(getSidsThunk());
                dispatch(hideEditModalThunk())
                toast.success('Сид успешно обнолен');
            })
            .catch(() => toast.error('Ошибка при обновлении сида'))
    }
}
