import React from "react";
import TextField from "../../common/form/text-field";
import BaseModal from "../../common/modals/base-modal";
import {Formik, Form} from 'formik';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";

import LabelValidation from "../../untils/validation/label-validation";
import {createOrUpdateLabelThunk, hideEditModalThunk} from "../../../store/thunks/labels-thunk";
import {getIsShowModalSelector, getLabelSelector} from "../../../store/selectors/labels-selectors";

const LabelForm = () => {

    const dispatch = useDispatch();
    let label = useSelector(getLabelSelector);
    let isShow = useSelector(getIsShowModalSelector);

    const handleClose = () => {
        dispatch(hideEditModalThunk())
    }

    if ( label === null ) {
        label = {
            name: '',
            color: ''
        };
    }

    const onSubmit = (values) => {
        dispatch(createOrUpdateLabelThunk(values));
    }

    const labelForm = () => {
        return <>
            <Formik initialValues={ {...label} } validationSchema={LabelValidation} onSubmit={onSubmit}>
                {({values, setFieldValue, errors}) => (
                    <Form>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Id"  id="id" name="id" disabled/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Name"  id="name" name="name"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="color" placeholder="Color"  id="color" name="color"/>
                        </div>
                        <Button type="submit" className={'form-control'} variant="primary">
                            Сохранить
                        </Button>
                    </Form>
                )}
            </Formik>
        </>;
    }

    return <>
        <BaseModal
            isShow={isShow}
            title={'Форма метки'}
            component={labelForm}
            handleClose={handleClose}
        />
    </>;
}

export default LabelForm;