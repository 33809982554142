import React from "react";
import SidCard from "./_part/sid-card";
import {useSelector} from "react-redux";
import {getDashboardSidsSelector} from "../../../store/selectors/dashboard-selectors";

const SidCapsSection = () => {

    const sids = useSelector(getDashboardSidsSelector);

    return <>
        <section className="mb-3 mb-lg-5">
            <div className="col-lg-3 mb-4 mb-lg-0">
                <div className="card h-100">
                    <div className="card-header">
                        <h4 className="card-heading">Наполнение капов сидов</h4>
                    </div>
                    <div className="card-body">
                        {sids.map(item => <SidCard key={item.id} sid={item} />)}
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default SidCapsSection;