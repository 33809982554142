import {LabelsListTypes} from "../constants/labels-constant";

const initialState = {
    items: [],
    selected: [],
    label: null,
    isShowModal: false,
    pagination: {
        perPage: 15,
        total: null,
        currentPage: 1,
        lastPage: null
    }
}

const leadsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LabelsListTypes.SET_LABELS:
            return {
                ...state,
                items: payload.data,
                pagination: {
                    ...state.pagination,
                    total: payload?.pagination?.total ?? payload.data.length,
                    lastPage: payload?.pagination?.lastPage ?? 1
                }
            }

        case LabelsListTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: payload
                }
            };

        case LabelsListTypes.SET_PER_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: payload
                }
            };

        case LabelsListTypes.SET_LABEL:
            return {
                ...state,
                label: {
                    ...payload
                }
            };

        case LabelsListTypes.SHOW_MODAL:
            return {
                ...state,
                isShowModal: true
            };

        case LabelsListTypes.HIDE_MODAL:
            return {
                ...state,
                isShowModal: false
            };

        default:
            return state;
    }
}

export default leadsReducer;