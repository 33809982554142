import Api from "./api";

export const getLeads = props => {
    const params = props;

    return Api.get('/admin/leads', { params } );
}

export const getLead = id => {
    return Api.get(`/admin/leads/${id}`);
}

export const updateLead = lead => {
    return Api.put(`/admin/leads/${lead.id}`, { ...lead });
}

export const removeLeads = props => {
    return Api.post('/admin/leads/remove-by-ids', {ids: props});
}

export const searchLeads = props => {
    return Api.post('/admin/leads/search', {keyword: props});
}

export const changeLeadsStatus = props => {
    const params = props;

    return Api.post('/admin/leads/change-status', {
        ids: params.ids,
        status: params.status
    });
}