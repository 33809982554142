import React from "react";
import {setBulkStatusThunk} from "../../../../store/thunks/leads-thunk";
import {useDispatch} from "react-redux";

const ChangeStatus = () => {
    const dispatch = useDispatch();
    const onChangeBulkStatus = (event) => dispatch(setBulkStatusThunk(event.target.value));

    return <>
        <div className="mb-4">
            <label className="form-label" htmlFor="change-status">Status</label>
            <select className="form-select" defaultValue={'default'} id="change-status" onChange={onChangeBulkStatus}>
                <option value="default" disabled>Выберите статус</option>
                <option value="hold">Hold</option>
                <option value="failed">Failed</option>
                <option value="success">Success</option>
            </select>
            <div className="form-text">Выберите статус из списка для выбранных лидов</div>
        </div>
    </>;
}

export default ChangeStatus;