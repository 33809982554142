import {SidsListTypes} from "../constants/sids-constant";
import ChangeStatus from "../../components/pages/sids-page/_part/change-status";

const initialState = {
    items: [],
    selected: [],
    isShowEditModal: false,
    isFetching: false,
    sid: null,
    bulk: {
        action: null,
        status: null,
        component: null,
        isShowModal: false
    },
    pagination: {
        perPage: 15,
        total: null,
        currentPage: 1,
        lastPage: null
    }
}

const bulkComponents = {
    bulk_change_status: ChangeStatus
}

const sidsReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SidsListTypes.SET_SIDS:
            return {
                ...state,
                items: payload.data,
                pagination: {
                    ...state.pagination,
                    total: payload.pagination.total,
                    lastPage: payload.pagination.lastPage
                }
            }

        case SidsListTypes.SET_SID:
            return {
                ...state,
                sid: {
                    ...payload
                },
                isShowEditModal: true
            }

        case SidsListTypes.HIDE_EDIT_MODAL:
            return {
                ...state,
                sid: null,
                isShowEditModal: false
            };

        case SidsListTypes.SET_BULK_ACTION:
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    component: bulkComponents[payload] ?? null,
                    isShowModal: !!bulkComponents[payload],
                    action: payload
                }
            };

        case SidsListTypes.SET_BULK_STATUS:
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    status: payload
                }
            };

        case SidsListTypes.SET_SELECTED_ITEMS:
            return {
                ...state,
                selected: [
                    ...payload
                ]
            };

        case SidsListTypes.HIDE_BULK_MODAL:
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    component: null,
                    isShowModal: false
                }
            };

        case SidsListTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: payload
                }
            };

        case SidsListTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: payload
            };

        default:
            return state;
    }
}

export default sidsReducer;