import React, {useEffect} from "react";
import PageTitle from "../../common/items/page-title";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import {
    getRequestLogsPaginationSelector,
    getRequestLogsSelector, getSelectedSelector
} from "../../../store/selectors/request-logs-selectors";
import {getRequestLogsThunk, setCurrentPageThunk, setSelectedThunk} from "../../../store/thunks/request-logs-thunk";
import {setCurrentPageAction} from "../../../store/actions/request-logs-action";
import Pagination from "../../common/pagination/pagination";
import ShowLog from "./show-log";

const RequestLogs = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const pagination = useSelector(getRequestLogsPaginationSelector);

    const selectHandle = (id) => dispatch(setSelectedThunk(id));
    const onChangePagination = (data) => dispatch(setCurrentPageThunk(data.selected + 1));
    const selectedIdx = useSelector(getSelectedSelector);

    useEffect(() => {
        const params = qs.parse(location.search);
        const page = params.page ? params.page : 1;

        dispatch(setCurrentPageAction(page));
        dispatch(getRequestLogsThunk());
    }, [location.search]);

    const logs = useSelector(getRequestLogsSelector);

    return <>
        <div className="container-fluid px-lg-4 px-xl-5">
            <div className="row request-logs">
                <PageTitle title={'Request Logs'}/>

                <section className="mb-5">
                    <div className="card">
                        <div className="card-body">

                            <div className="col-md-12">
                                <div className="row head-request-list">
                                    <div className="col-md-2 head-item">
                                        <label className="form-label">Id</label>
                                    </div>
                                    <div className="col-md-2 head-item">
                                        <label className="form-label">Method</label>
                                    </div>
                                    <div className="col-md-2 head-item">
                                        <label className="form-label">URI</label>
                                    </div>
                                    <div className="col-md-2 head-item">
                                        <label className="form-label">IP</label>
                                    </div>
                                    <div className="col-md-2 head-item">
                                        <label className="form-label">Status</label>
                                    </div>
                                    <div className="col-md-2 head-item">
                                        <label className="form-label">Created</label>
                                    </div>
                                </div>

                                <hr/>
                                { logs.map((item, key) => (
                                    <div key={key}>
                                        <div className="row items-request-list" onClick={() => selectHandle(item.id)} >
                                            <div className="col-md-2 item">
                                                <label className="form-label">{ (pagination.currentPage - 1) * pagination.perPage + 1 + key} </label>
                                            </div>
                                            <div className="col-md-2 item">
                                                <label className="form-label">{item.method}</label>
                                            </div>
                                            <div className="col-md-2 item">
                                                <label className="form-label">{item.uri}</label>
                                            </div>
                                            <div className="col-md-2 item">
                                                <label className="form-label">{item.ip}</label>
                                            </div>
                                            <div className={`col-md-2 item ${item.status <= 300 ? 'bg-green' : 'bg-orange'}`}>
                                                <label className={`form-label`}>{item.status}</label>
                                            </div>
                                            <div className="col-md-2 item">
                                                <label className="form-label">{item.created_at}</label>
                                            </div>
                                        </div>
                                        {selectedIdx.includes(item.id) && <ShowLog key={key} item={item} />}
                                    </div>
                                )) }
                            </div>

                            <Pagination
                                currentPage={pagination.currentPage}
                                pageCount={pagination.lastPage}
                                perPage={pagination.perPage}
                                total={pagination.total}
                                handlePageClick={onChangePagination}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </>;
}

export default RequestLogs;