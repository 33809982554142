import React from "react";
import Sprite from "../../../assets/icons/orion-svg-sprite.svg";

const Icon = props => {

    return <>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             className={`svg-icon svg-icon-md ${props.class}`}>
            <use xlinkHref={`${Sprite}#${props.name}`} />
        </svg>
    </>;
}

export default Icon;