import React from "react";
import {useField} from "formik";
import {FormControl} from "react-bootstrap";
import Select from "react-select";

const SelectField = ({ valueParam, label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { value, touched, error } = meta;
    const { setValue } = helpers;

    if (field.placeholder == undefined || field.placeholder == null) {
        props.placeholder = '';
    }

    const findValue = () => {
        if ( (value === 0 || value === false || value) && props.options) {
            if(!props.isMulti) {
                return props.options.find(option => option[valueParam] == value);
            } else {
                return props.options.filter(option => value.includes(option[valueParam]));
            }
        }

        return null;
    }

    const onChangeHandle = (selectedOption) => {
        if(!props.isMulti) {
            setValue(selectedOption[valueParam])
        } else {
            let options = [];
            if (Array.isArray(selectedOption) && selectedOption.length > 0) {
                selectedOption.map((el) => {
                    options.push(el[valueParam]);
                })
            }
            setValue(options);
        }
    }

    return <>
        {label && <label className="form-label" htmlFor="change-status">{label}</label>}
        <Select
            {...field}
            {...props}
            value={findValue()}
            onChange={onChangeHandle}
        />
        <div className="form-text">Выберите статус из списка для выбранных лидов</div>
        {touched && error ? (
            <FormControl.Feedback type="invalid" className="d-block">
                {error}
            </FormControl.Feedback>
        ) : null}
    </>;
};

export default SelectField;
