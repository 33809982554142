import React from "react";
import {ProgressBar} from "react-bootstrap";

const SidCard = ({ sid }) => {

    let progress = 0;
    if ( sid.leads_today_count !== 0 ) {
        progress = sid.leads_today_count / sid.limit * 100;
    }

    return <>
        <div className="chart-holder w-100 mb-3">
            <div>
                <span className="mb-0">
                    {`${sid.name} - ${sid.sid}`}

                    <div className={`float-end badge ${sid.status ? 'bg-success' : 'bg-danger'}`}>
                          {`${sid.status ? 'On' : 'Off'}`}
                    </div>
                </span>
                <ProgressBar variant={'success'} animated now={progress} label={`${progress}% completed`} />
                <span className="text-gray-500">{`${sid.leads_today_count}`} Today leads</span>
            </div>
        </div>
    </>;
}

export default SidCard;