import {RequestLogsTypes} from "../constants/request-logs-constant";

const initialState = {
    items: [],
    log: null,
    selected: [],
    pagination: {
        perPage: 15,
        total: null,
        currentPage: 1,
        lastPage: null
    }
}

const requestLogsReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case RequestLogsTypes.SET_REQUEST_LOGS:
            return {
                ...state,
                items: payload.data,
                pagination: {
                    ...state.pagination,
                    total: payload.pagination.total,
                    lastPage: payload.pagination.lastPage
                }
            }

        case RequestLogsTypes.SET_REQUEST_LOG:
            return {
                ...state,
                log: {
                    ...payload
                },
                isShowEditModal: true
            }

        case RequestLogsTypes.SET_SELECTED:
            return {
                ...state,
                selected: [
                    ...payload
                ]
            };

        case RequestLogsTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: payload
                }
            };

        default:
            return state;
    }
}

export default requestLogsReducer;