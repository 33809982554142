import {AuthTypes} from "../constants/auth-constant";

const initialState = {
    auth: {
        access_token: null,
        user: null
    },
    isAuth: false
}

const authReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case AuthTypes.SET_AUTH:
            return {
                isAuth: true,
                auth: {
                    access_token: payload.access_token
                }
            }

        case AuthTypes.SET_LOGOUT:
            return {
                isAuth: false,
                auth: {
                    access_token: null
                }
            }

        default:
            return state;
    }
}

export default authReducer;