import React from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    changeSidsStatusThunk,
    refreshSidsThunk,
    removeSelectedItemsThunk,
    setBulkActionThunk,
    setPerPageThunk
} from "../../../../store/thunks/sids-thunk";
import {
    getActionSelector,
    getBulkActionSelector, getIsFetchingSelector,
    getSelectedItemsSelector
} from "../../../../store/selectors/sids-selectors";

const BulkAction = () => {
    const dispatch = useDispatch();

    const onChangePerPage = (event) => dispatch(setPerPageThunk(event.target.value));
    const onChangeBulkAction = (event) => dispatch(setBulkActionThunk(event.target.value));
    const selectedBulk = useSelector(getBulkActionSelector);

    const action = useSelector(getActionSelector);
    const sidsIdx = useSelector(getSelectedItemsSelector);
    const isFetching = useSelector(getIsFetchingSelector);

    const handleRefreshSids = () => {
        dispatch(refreshSidsThunk())
    }

    const runActionHandle = () => {
        if ( !sidsIdx.length ) {
            toast.error('Выберите список сидов!');
            return;
        }

        console.log('action: ' + action)
        switch (action) {
            case 'bulk_delete':

                dispatch(removeSelectedItemsThunk());
                break;

            case 'bulk_change_status':

                dispatch(changeSidsStatusThunk());
                break;
        }
    }

    return <>
        <div className="dataTable-top">
            <div className="dataTable-dropdown">
                 <span className="me-2" id="bulk-action">
                    <select className="form-select form-select-sm d-inline w-auto" onChange={onChangeBulkAction} value={selectedBulk ?? 'undefined'}>
                       <option value={'undefined'}>Bulk Actions</option>
                       <option value={'bulk_delete'}>Delete</option>
                       <option value={'bulk_change_status'}>Change status</option>
                    </select>
                     {selectedBulk && selectedBulk !== 'undefined' && <button
                        className="btn btn-sm btn-outline-primary align-top"
                        onClick={runActionHandle}> Apply
                    </button>}
                 </span>
                <label>
                    <select className="dataTable-selector form-select form-select-sm" onChange={onChangePerPage}>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                    </select>
                    entries per page
                </label>
            </div>
            <div className="dataTable-search">
                <input className="dataTable-input form-control form-control-sm" placeholder="Search..." type="text"/>

                <button
                    disabled={isFetching}
                    type='variant'
                    className="btn btn-primary"
                    onClick={handleRefreshSids}
                    title={'Обновляет список сидов с LBDT'}>
                    <i className="fas fa-circle-notch"></i>
                </button>
            </div>
        </div>
    </>;
}

export default BulkAction;