import React from "react";
import {NavLink} from "react-router-dom";

const StatusCard = props => {

    const statusIcons = {
        success: {
            icon: 'fa-clipboard',
            class: 'bg-green'
        },
        failed: {
            icon: 'fa-receipt',
            class: 'bg-red'
        },
        hold: {
            icon: 'fa-dolly-flatbed',
            class: 'bg-blue'
        },
        created: {
            icon: 'fa-server',
            class: 'bg-yellow'
        }
    };

    const date = new Date();
    const today = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    return <>
        <div className="col-xl-3 col-md-6 mb-4">
            <div className="card-widget h-100">
                <div className="card-widget-body">
                    <div className={`dot me-3 ${statusIcons[props.status].class}`}></div>
                    <div className="text">
                        <NavLink to={`/page/leads?status=${props.status}&created_at=${today}`}>
                            <h6 className="mb-0">
                                {props.status.toUpperCase()}
                            </h6>
                            <span className="text-gray-500">
                                Leads: {props.count}
                            </span>
                        </NavLink>
                    </div>
                </div>
                <div className={`icon text-white ${statusIcons[props.status].class}`}>
                    <i className={`fas ${statusIcons[props.status].icon}`}></i>
                </div>



            </div>
        </div>
    </>;
}

export default StatusCard;