import React, {useEffect, useState} from "react";
import BrandSvg from '../../../assets/icons/brand-1.svg';
import Background from '../../../assets/images/login-background.jpg';
import {sendAuthThunk} from "../../../store/thunks/auth-thunk";
import {useDispatch, useSelector} from "react-redux";
import {getIsAuthSelector} from "../../../store/selectors/auth-selector";
import {useHistory} from "react-router";

const LoginPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const isAuth = useSelector(getIsAuthSelector);
    useEffect(() => {
        if ( isAuth ) {
            history.push(`/dashboard`);
        }
    }, [isAuth]);

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();

        dispatch(sendAuthThunk({email, password}));
    }

    return <>
        <div className="container-fluid px-0">
            <div className="row gx-0 min-vh-100">
                <div className="col-md-9 col-lg-6 col-xl-4 px-5 d-flex align-items-center shadow">
                    <div className="w-100 py-5">
                        <div className="text-center">
                            <img className="img-fluid mb-4" src={BrandSvg} style={{maxWidth: '6rem'}} />
                            <h1 className="h4 text-uppercase mb-5">Welcome Back</h1>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Email Address</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    autoComplete="off"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <div className="row">
                                    <div className="col">
                                        <label className="form-label">Password</label>
                                    </div>
                                </div>
                                <input
                                    className="form-control"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="d-grid mb-5">
                                <button className="btn btn-primary text-uppercase" type="submit" disabled={!validateForm()}>Sign in</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-3 col-lg-6 col-xl-8 d-none d-md-block">
                    <div className="bg-cover h-100 me-n3" style={{ backgroundImage: `url(${Background})` }}></div>
                </div>
            </div>
        </div>
    </>;
}

export default LoginPage;