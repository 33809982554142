import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getSidThunk,
    setSelectedItemsThunk,
    updateSidThunk
} from "../../../store/thunks/sids-thunk";
import {getSelectedItemsSelector} from "../../../store/selectors/sids-selectors";
import {getLabelsThunk} from "../../../store/thunks/labels-thunk";
import SidLabel from "./_part/sid-label";

const Item = ({ item }) => {

    const dispatch = useDispatch();
    const selectHandle = (id) => dispatch(setSelectedItemsThunk(id));
    const selectedIdx = useSelector(getSelectedItemsSelector);

    const handleSid = (id) => {
        dispatch(getLabelsThunk());
        dispatch(getSidThunk(id));
    }

    const handleChange = (sid) => {
        dispatch(updateSidThunk(sid));
    }

    return <>
        <tr>
            <td>
                <span className="form-check">
                  <input className="form-check-input"
                         type="checkbox"
                         onChange={() => selectHandle(item.id)}
                         value={item.id}
                         checked={selectedIdx.includes(item.id)}
                  />
                </span>
            </td>
            <td>{item.id}</td>
            <td>
                {item.name}
            </td>
            <td>{item.sid}</td>
            <td>
                <SidLabel item={item} />
            </td>
            <td>{item.limit}</td>
            <td>{item.start} - {item.end}</td>
            <td>
                <input
                    onChange={() => handleChange({'id': item.id, 'is_self': !item.is_self})}
                    className="form-check-input"
                    type="checkbox"
                    checked={item.is_self}
                />
            </td>
            <td>
                <input
                    onChange={() => handleChange({'id': item.id, 'status': !item.status})}
                    className="form-check-input"
                    type="checkbox"
                    checked={item.status}
                />
            </td>
            <td>{item.created_at}</td>
            <td>
                <i className="m-lg-1 fas fa-edit" onClick={() => handleSid(item.id)}></i>
            </td>
        </tr>
    </>;
}

export default Item;