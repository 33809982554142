import {changeLeadsStatus, getLead, getLeads, removeLeads, searchLeads, updateLead} from "../../api/leads-api";
import {
    hideBulkModalAction, hideEditModalAction,
    setBulkAction, setBulkComponentAction,
    setBulkStatusAction,
    setFilterAction, setLeadAction,
    setLeadsAction,
    setPerPageAction, setSearchAction,
    setSelectedItemsAction
} from "../actions/leads-action";
import {setCurrentPageAction} from "../actions/leads-action";
import {toast} from "react-toastify";

export const getLeadsThunk = () => {
    return (dispatch, state) => {
        let leads = state().leadsReducer;
        let filter = leads.filter;

        getLeads({
            'page':  leads.pagination.currentPage,
            'perPage':  leads.pagination.perPage,
            ...filter
        }).then(response => {
            dispatch(setLeadsAction(response.data));
        });
    }
}

export const setCurrentPageThunk = (page) => {
    return (dispatch, state) => {

        if (state().leadsReducer.pagination.currentPage !== page) {
            dispatch(setCurrentPageAction(page));
            dispatch(getLeadsThunk());
        }
    }
}

export const setPerPageThunk = (perPage) => {
    return (dispatch) => {
        dispatch(setPerPageAction(perPage));
        dispatch(getLeadsThunk());
    }
}

export const setFilterThunk = (params) => {
    return (dispatch) => {
        dispatch(setFilterAction(params));
    }
}

// Add the selected one or remove it if it is present
export const setSelectedItemsThunk = (id) => {
    return (dispatch, state) => {
        const selectedItems = state().leadsReducer.selected;

        if ( !selectedItems.includes(id) ) {
            selectedItems.push(id);
        } else {
            let index = selectedItems.indexOf(id);
            selectedItems.splice(index, 1);
        }

        dispatch(setSelectedItemsAction(selectedItems));
    }
}

export const setBulkActionThunk = (action) => {
    return (dispatch) => {
        dispatch(setBulkAction(action));
    }
}

export const setBulkStatusThunk = (status) => {
    return (dispatch) => {
        dispatch(setBulkStatusAction(status));
    }
}

export const hideBulkModalThunk = () => {
    return (dispatch) => {
        dispatch(hideBulkModalAction());
    }
}

export const hideEditModalThunk = () => {
    return (dispatch) => {
        dispatch(hideEditModalAction());
    }
}

export const setBulkComponentThunk = (component) => {
    return (dispatch) => {
        dispatch(setBulkComponentAction(component));
    }
}

export const removeSelectedItemsThunk = () => {
    return (dispatch, state) => {
        const ids = state().leadsReducer.selected;

        removeLeads(ids).then(() => {
            dispatch(getLeadsThunk());
            dispatch(setSelectedItemsAction([]));
        });
    }
}

export const changeLeadsStatusThunk = () => {
    return (dispatch, state) => {
        const ids = state().leadsReducer.selected;
        const status = state().leadsReducer.bulk.status;

        changeLeadsStatus({ids, status}).then(() => {
            dispatch(setSelectedItemsAction([]));
            dispatch(getLeadsThunk());
            dispatch(setBulkActionThunk('undefined'));
            toast.success('Статус лидам обновлен!');
        });
    }
}

export const updateLeadThunk = (lead) => {
    return (dispatch) => {
        updateLead(lead)
            .then(() => {
                dispatch(getLeadsThunk());
                dispatch(hideEditModalThunk())
                toast.success('Лид успешно обнолен');
            })
            .catch(() => toast.error('Ошибка при обновлении лида'))
    }
}

export const getLeadThunk = (id) => {
    return (dispatch) => {
        getLead(id)
            .then(response => {
                dispatch(setLeadAction(response.data.data));
            })
            .catch(error => toast.error('Ошибка при получении лида'))
    }
}

export const setSearchThunk = (keyword) => {
    return (dispatch) => {
        searchLeads(keyword)
            .then(response => {
                dispatch(setLeadsAction(response.data));
            })
            .catch(error => {
                toast.error('Ошибка при поиске лидов')
            })
    }
}