import {toast} from "react-toastify";

import {getLog, getLogs} from "../../api/request-logs-api";
import {
    setCurrentPageAction,
    setPerPageAction,
    setRequestLogAction,
    setRequestLogsAction, setSelectedAction
} from "../actions/request-logs-action";
import {setselectedAction} from "../actions/leads-action";

export const getRequestLogsThunk = () => {
    return (dispatch, state) => {
        let logs = state().requestLogsReducer;

        getLogs({
            'page':  logs.pagination.currentPage,
            'perPage':  logs.pagination.perPage,
        }).then(response => {
            dispatch(setRequestLogsAction(response.data));
        });
    }
}

export const getRequestLogThunk = (id) => {
    return (dispatch) => {
        getLog(id)
            .then(response => {
                dispatch(setRequestLogAction(response.data.data));
            })
            .catch(error => toast.error('Ошибка при получении лога'))
    }
}

export const setCurrentPageThunk = (page) => {
    return (dispatch, state) => {

        if (state().requestLogsReducer.pagination.currentPage !== page) {
            dispatch(setCurrentPageAction(page));
            dispatch(getRequestLogsThunk());
        }
    }
}

export const setPerPageThunk = (perPage) => {
    return (dispatch) => {
        dispatch(setPerPageAction(perPage));
        dispatch(getRequestLogsThunk());
    }
}

export const setSelectedThunk = (id) => {
    return (dispatch, state) => {
        const selected = state().requestLogsReducer.selected;

        if ( !selected.includes(id) ) {
            selected.push(id);
        } else {
            let index = selected.indexOf(id);
            selected.splice(index, 1);
        }

        dispatch(setSelectedAction(selected));
    }
}
