import React from "react";
import {useField} from "formik";
import {FormControl} from "react-bootstrap";

const TextField = ({ type, label, ...props }) => {
    const [field, meta] = useField(props);
    const { touched, error } = meta;

    return <>
        {label && <label>{label}</label>}
        <FormControl
            {...field}
            {...props}
            type={type}
            isInvalid={touched && error}
        />
        {touched && error ? (
            <FormControl.Feedback type="invalid">
                {error}
            </FormControl.Feedback>
        ) : null}
    </>;
}

export default TextField;
