import { LabelsListTypes } from "../constants/labels-constant";

export const setLabelsAction = payload => ({
    type: LabelsListTypes.SET_LABELS,
    payload
});

export const setCurrentPageAction = payload => ({
    type: LabelsListTypes.SET_CURRENT_PAGE,
    payload
});

export const setPerPageAction = payload => ({
    type: LabelsListTypes.SET_PER_PAGE,
    payload
});

export const setLabelAction = payload => ({
    type: LabelsListTypes.SET_LABEL,
    payload
});

export const hideModalAction = payload => ({
    type: LabelsListTypes.HIDE_MODAL,
    payload
});

export const showModalAction = payload => ({
    type: LabelsListTypes.SHOW_MODAL,
    payload
});
