import React from "react";

const ShowLog = ({ item }) => {

    let body = JSON.parse(item.body);
    let headers = JSON.parse(item.headers);
    let response = JSON.parse(item.response);

    return <>
        <div className="row show-log">
            <div className="col-md-4 info">
                <label className={'form-label head-info'}>BODY</label>
                {body && Object.keys(body).map((key, index) =>
                    <label key={index} className={'form-label'} >{key}: {JSON.parse(item.body)[key] }</label>
                )}
            </div>
            <div className="col-md-4 info">
                <label className={'form-label head-info'}>HEADERS</label>
                {headers && Object.keys(headers).map((key, index) =>
                    <label key={index} className={'form-label'} >{key}: { headers[key] }</label>
                )}
            </div>
            <div className="col-md-4 info">
                <label className={'form-label head-info'}>RESPONSE</label>
                {response && Object.keys(response).map((key, index) =>
                    <label key={index} className={'form-label'} >{key}: {JSON.stringify(response[key])}</label>
                )}
            </div>
        </div>
    </>;
}

export default ShowLog;