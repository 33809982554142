import { combineReducers } from "redux";
import leadsReducer from "./leads-reducer";
import authReducer from "./auth-reducer";
import dashboardReducer from "./dashboard-reducer";
import appReducer from "./app-reducer";
import sidsReducer from "./sids-reducer";
import labelsReducer from "./labels-reducer";
import requestLogsReducer from "./request-logs-reducer";

export default combineReducers({
    authReducer,
    appReducer,
    dashboardReducer,
    leadsReducer,
    sidsReducer,
    labelsReducer,
    requestLogsReducer
});
