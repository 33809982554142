import {AuthTypes} from "../constants/auth-constant";

export const setAuthAction = payload => ({
    type: AuthTypes.SET_AUTH,
    payload
});

export const setUserAction = payload => ({
    type: AuthTypes.SET_USER,
    payload
});

export const setLogoutAction = payload => ({
    type: AuthTypes.SET_LOGOUT,
});
