import {DashboardTypes} from "../constants/dashboard-constant";

const initialState = {
    statuses: [],
    sids: []
}

const dashboardReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case DashboardTypes.SET_DASHBOARD:
            return {
                ...state,
                statuses: payload.statuses,
                sids: payload.sids
            }

        default:
            return state;
    }
}

export default dashboardReducer;