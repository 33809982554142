import {LeadsListTypes} from '../constants/leads-constant';
import ChangeStatus from "../../components/pages/leads-page/_part/change-status";

const initialState = {
    items: [],
    selected: [],
    filter: {},
    search: null,
    lead: null,
    isShowEditModal: false,
    bulk: {
        action: null,
        status: null,
        component: null,
        isShowModal: false
    },
    pagination: {
        perPage: 15,
        total: null,
        currentPage: 1,
        lastPage: null
    }
}

const bulkComponents = {
    bulk_change_status: ChangeStatus
}

const leadsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LeadsListTypes.SET_LEADS:
            return {
                ...state,
                items: payload.data,
                pagination: {
                    ...state.pagination,
                    total: payload?.pagination?.total ?? payload.data.length,
                    lastPage: payload?.pagination?.lastPage ?? 1
                }
            }

        case LeadsListTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: payload
                }
            };

        case LeadsListTypes.SET_BULK_ACTION:
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    component: bulkComponents[payload] ?? null,
                    isShowModal: !!bulkComponents[payload],
                    action: payload
                }
            };

        case LeadsListTypes.HIDE_BULK_MODAL:
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    component: null,
                    isShowModal: false
                }
            };

        case LeadsListTypes.HIDE_EDIT_MODAL:
            return {
                ...state,
                lead: null,
                isShowEditModal: false
            };

        case LeadsListTypes.SET_BULK_STATUS:
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    status: payload
                }
            };

        case LeadsListTypes.SET_BULK_COMPONENT:
            return {
                ...state,
                bulk: {
                    ...state.bulk,
                    component: payload
                }
            };

        case LeadsListTypes.SET_PER_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: payload
                }
            };

        case LeadsListTypes.SET_FILTER:
            return {
                ...state,
                filter: {
                    ...payload
                }
            };

        case LeadsListTypes.SET_LEAD:
            return {
                ...state,
                lead: {
                    ...payload
                },
                isShowEditModal: true
            };

        case LeadsListTypes.SET_SELECTED_ITEMS:
            return {
                ...state,
                selected: [
                    ...payload
                ]
            };

        case LeadsListTypes.SET_SEARCH:
            return {
                ...state,
                search: payload
            };

        default:
            return state;
    }
}

export default leadsReducer;