import React from "react";
import * as Yup from "yup";

const EditSidValidation = () => {
    return Yup.object({
        name: Yup.string()
            .required('Required'),
        sid: Yup.string()
            .required('Required'),
        status: Yup.string()
            .required('Required')
    });
}

export default EditSidValidation;