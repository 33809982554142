import Api from "./api";

export const getLabels = props => {
    const params = props;

    return Api.get('/admin/labels', { params } );
}

export const getLabel = id => {
    return Api.get(`/admin/labels/${id}`);
}

export const createOrUpdateLabel = label => {
    if ( label.id ) {
        return Api.put(`/admin/labels/${label.id}`, { ...label });
    }

    return Api.post(`/admin/labels`, { ...label });
}

export const removeLabels = props => {
    return Api.post('/admin/labels/remove-by-ids', {ids: props});
}
