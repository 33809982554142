import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeLeadsStatusThunk, getLeadsThunk,
    removeSelectedItemsThunk,
    setBulkActionThunk,
    setPerPageThunk, setSearchThunk
} from "../../../../store/thunks/leads-thunk";
import {
    getActionSelector,
    getBulkActionSelector,
    getSelectedItemsSelector
} from "../../../../store/selectors/leads-selectors";
import {toast} from "react-toastify";

const BulkAction = () => {
    const dispatch = useDispatch();

    const onChangePerPage = (event) => dispatch(setPerPageThunk(event.target.value));
    const onChangeBulkAction = (event) => dispatch(setBulkActionThunk(event.target.value));
    const onChangeSearch = (event) => {
        const keyword = event.target.value;

        if ( !keyword.length ) {
            dispatch(getLeadsThunk());
            return;
        }

        dispatch(setSearchThunk(keyword));
    };
    const selectedBulk = useSelector(getBulkActionSelector);

    const action = useSelector(getActionSelector);
    const leadsIdx = useSelector(getSelectedItemsSelector);
    const runActionHandle = () => {
        if ( !leadsIdx.length ) {
            toast.error('Выберите список лидов!');
            return;
        }

        switch (action) {
            case 'bulk_delete':

                dispatch(removeSelectedItemsThunk());
                break;

            case 'bulk_change_status':

                dispatch(changeLeadsStatusThunk());
                break;
        }
    }

    return <>
        <div className="dataTable-top">
            <div className="dataTable-dropdown">
                 <span className="me-2" id="bulk-action">
                    <select className="form-select form-select-sm d-inline w-auto" onChange={onChangeBulkAction} value={selectedBulk ?? 'undefined'}>
                       <option value={'undefined'}>Bulk Actions</option>
                       <option value={'bulk_delete'}>Delete</option>
                       <option value={'bulk_change_status'}>Change status</option>
                    </select>
                     {selectedBulk && selectedBulk !== 'undefined' && <button
                        className="btn btn-sm btn-outline-primary align-top"
                        onClick={runActionHandle}> Apply
                    </button>}
                 </span>
                <label>
                    <select className="dataTable-selector form-select form-select-sm" onChange={onChangePerPage}>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                    </select>
                    entries per page
                </label>
            </div>
            <div className="dataTable-search">
                <input className="dataTable-input form-control form-control-sm" placeholder="Search..." type="text" onChange={onChangeSearch}/>
            </div>
        </div>
    </>;
}

export default BulkAction;