export const SidsListTypes = {
  SET_SIDS: 'sids-list/SET_SIDS',
  SET_SID: 'sids-list/SET_SID',
  SET_CURRENT_PAGE: 'sids-list/SET_CURRENT_PAGE',
  SET_PER_PAGE: 'sids-list/SET_PER_PAGE',
  SET_SELECTED_ITEMS: 'sids-list/SET_SELECTED_ITEMS',
  HIDE_EDIT_MODAL: 'sids-list/HIDE_EDIT_MODAL',
  SET_BULK_ACTION: 'sids-list/SET_BULK_ACTION',
  SET_BULK_STATUS: 'sids-list/SET_BULK_STATUS',
  SET_IS_FETCHING: 'sids-list/SET_IS_FETCHING',
  HIDE_BULK_MODAL: 'sids-list/HIDE_BULK_MODAL',
};

export const SidSelectStatuses = [
  {value: false, label: 'Выкл.'},
  {value: true, label: 'Вкл.'}
];