import React from "react";
import {updateSidThunk} from "../../../../store/thunks/sids-thunk";
import {useDispatch} from "react-redux";

const SidLabel = ({ item }) => {
    const dispatch = useDispatch();

    const handleChange = (sid) => {
        dispatch(updateSidThunk(sid));
    }

    if ( !item.label ) {
        return <></>;
    }

    return <>
        <div className="choices" data-type="text">
            <div className="choices__list choices__list--multiple">
                <div className="choices__item choices__item--selectable" style={{
                    backgroundColor: item.label.color,
                    border: `1px solid ${item.label.color}`
                }}>
                    { item.label.name }
                    <button type="button" className="choices__button"
                            onClick={() => handleChange({'id': item.id, 'label_id': null})} >
                        Remove item
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default SidLabel;