import React from "react";
import ReactPaginate from 'react-paginate';

const Pagination = props => {

    let from = (props.currentPage - 1) * props.perPage + 1;
    let to = props.perPage * props.currentPage;

    if ( props.pageCount === props.currentPage ) {
        to = props.total;
    }

    return <>
        <div className="dataTable-bottom">
            <div className="dataTable-info">
                Showing {from} to {to} of {props.total} entries
            </div>

            <nav className="dataTable-pagination">
                <ReactPaginate
                    previousLabel={<span className="pager">‹</span>}
                    nextLabel={<span className="pager">›</span>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={props.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={7}
                    onPageChange={props.handlePageClick}
                    forcePage={props.currentPage - 1}
                    containerClassName={'dataTable-pagination-list'}
                    activeClassName={'active'}
                />
            </nav>

        </div>
    </>;
}

export default Pagination;