import Api from "./api";

export const getSids = props => {
    const params = props;

    return Api.get('/admin/sids', { params } );
}

export const getSid = id => {
    return Api.get(`/admin/sids/${id}`);
}

export const updateSid = sid => {
    return Api.put(`/admin/sids/${sid.id}`, { ...sid });
}

export const updateBooleanField = (id, field, value) => {
    return Api.put(`/admin/sids/${id}`, {
        field: value
    });
}

export const removeSids = props => {
    return Api.post('/admin/sids/remove-by-ids', {ids: props});
}

export const refreshSids = () => {
    return Api.get('/admin/sids/refresh');
}

export const changeSidsStatus = props => {
    const params = props;

    return Api.post('/admin/sids/change-status', {
        ids: params.ids,
        status: params.status
    });
}