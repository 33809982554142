import {BrowserRouter as Router} from "react-router-dom";
import {Route} from "react-router";
import {ToastContainer} from "react-toastify";
import LeadsPage from "./components/pages/leads-page";
import SidsPage from "./components/pages/sids-page";
import LabelsPage from "./components/pages/labels-page";
import LoginPage from "./components/pages/login-page";
import AdminLayout from './components/pages/_layouts/admin-layout';
import DashboardPage from "./components/pages/dashboard-page";
import RequestLogs from "./components/pages/request-logs";
import NotFoundPage from "./components/pages/not-found-page";

import './assets/styles/all.css';
import './assets/styles/styles.css';
import './assets/styles/custom.css';
import "react-toastify/dist/ReactToastify.min.css";


// import './assets/scripts/bootstrap.bundle.min';
// import './assets/scripts/theme';

// https://demo.bootstrapious.com/bubbly/1-0/index.html

const App = () => {
    // const isAuth = useSelector(getIsAuthSelector);

    return <>
        <Router>
            <Route path="/login" component={LoginPage} />

            {/*Todo FIX*/}
            {/*{isAuth &&*/}
            {/*    <>*/}
            {/*        <Route path="/dashboard" render={AdminLayout(DashboardPage)} />*/}
            {/*        <Route path="/page/leads" render={AdminLayout(LeadsPage)} />*/}
            {/*    </>*/}
            {/*}*/}
            <>
                <Route path="/dashboard" render={AdminLayout(DashboardPage)} />
                <Route exact path="/page/leads" render={AdminLayout(LeadsPage)} />
                <Route exact path="/page/sids" render={AdminLayout(SidsPage)} />
                <Route exact path="/page/labels" render={AdminLayout(LabelsPage)} />
                <Route exact path="/page/request-logs" render={AdminLayout(RequestLogs)} />

                {/*<Route path="*" component={AdminLayout(NotFoundPage)} />*/}
            </>

            <ToastContainer/>
        </Router>
    </>
}

export default App;
