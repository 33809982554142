import Api from "./api";

export const getLogs = props => {
    const params = props;

    return Api.get('/admin/request-logs', { params } );
}

export const getLog = id => {
    return Api.get(`/admin/request-logs/${id}`);
}

export const removeLog = id => {
    return Api.post(`/admin/request-logs/${id}`);
}
