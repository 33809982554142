import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDashboardThunk} from "../../../store/thunks/dashboard-thunk";
import {getDashboardStatusesSelector} from "../../../store/selectors/dashboard-selectors";
import StatusCard from "./_part/status-card";

const StatusCardSection = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDashboardThunk());
    }, []);

    const statuses = useSelector(getDashboardStatusesSelector);

    const renderStatuses = () => {
        return statuses.map((item, key) => (
            <StatusCard
                key={key}
                status={item.status}
                count={item.count}
            />
        ));
    }

    return <>
        <section className="mb-3 mb-lg-5">
            <div className="row">
                {renderStatuses()}
            </div>
        </section>
    </>;
}

export default StatusCardSection;