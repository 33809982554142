import React from "react";
import Icon from "../items/Icon";
import Avatar from '../../../assets/images/avatar.jpg';
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logoutThunk} from "../../../store/thunks/auth-thunk";
import {toggleMenuThunk} from "../../../store/thunks/app-thunk";
import {getToggleMenuSelector} from "../../../store/selectors/app-selector";

const Header = () => {
    const dispatch = useDispatch();
    const toggleMenu = useSelector(getToggleMenuSelector);

    const logoutHandle = () =>  {
        dispatch(logoutThunk());
    }

    const toggleMenuHandle = () =>  {
        dispatch(toggleMenuThunk(!toggleMenu));
    }

    return <>
        <header className="header">
            <nav className="navbar navbar-expand-lg px-4 py-2 bg-white shadow">
                <a className="sidebar-toggler text-gray-500 me-4 me-lg-5 lead" onClick={toggleMenuHandle}>
                    <i className="fas fa-align-left"></i>
                </a>
                <NavLink to={'/dashboard'} className="navbar-brand fw-bold text-uppercase text-base">
                    <span className="d-none d-brand-partial">Mini </span>
                    <span className="d-none d-sm-inline">LBDT</span>
                </NavLink>
                <ul className="ms-auto d-flex align-items-center liaria-haspopupst-unstyled mb-0">
                    <li className="nav-item">
                        <form className="ms-auto d-none d-lg-block" id="searchForm">
                            <div className="form-group position-relative mb-0">
                                <button className="position-absolute bg-white border-0 p-0" type="submit">
                                    <i className="o-search-magnify-1 text-gray-500 text-lg"></i>
                                </button>
                                <input className="form-control form-control-sm border-0 shadow-0 ps-4" type="search"
                                       placeholder="Search ..."/>
                            </div>
                        </form>
                    </li>
                    <li className="nav-item dropdown me-2">
                        <a className="nav-link text-gray-400 px-1" id="notifications" href="/cms-post.html#"
                           data-bs-toggle="dropdown" aria-expanded="false">
                            <Icon name={'sales-up-1'} class={'svg-icon-heavy'}/>
                            <span className="notification-badge bg-green"></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end text-sm" aria-labelledby="notifications">
                            <a className="dropdown-item" href="/cms-post.html#">
                                <div className="d-flex align-items-center">
                                    <div className="icon icon-sm bg-indigo text-white">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="text ms-2">
                                        <p className="mb-0">You have 2 followers</p>
                                    </div>
                                </div>
                            </a>
                            <a className="dropdown-item" href="/cms-post.html#">
                                <div className="d-flex align-items-center">
                                    <div className="icon icon-sm bg-green text-white">
                                        <i className="fas fa-envelope"></i>
                                    </div>
                                    <div className="text ms-2">
                                        <p className="mb-0">You have 6 new messages</p>
                                    </div>
                                </div>
                            </a>
                            <a className="dropdown-item" href="/cms-post.html#">
                                <div className="d-flex align-items-center">
                                    <div className="icon icon-sm bg-blue text-white">
                                        <i className="fas fa-upload"></i>
                                    </div>
                                    <div className="text ms-2">
                                        <p className="mb-0">Server rebooted</p>
                                    </div>
                                </div>
                            </a>
                            <a className="dropdown-item" href="/cms-post.html#">
                                <div className="d-flex align-items-center">
                                    <div className="icon icon-sm bg-indigo text-white">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="text ms-2">
                                        <p className="mb-0">You have 2 followers</p>
                                    </div>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item text-center" href="/cms-post.html#">
                                <small className="fw-bold text-uppercase">View all notifications</small>
                            </a>
                        </div>
                    </li>
                    <li className="nav-item dropdown me-2 me-lg-3">
                        <a className="nav-link text-gray-400 px-1" id="messages" href="/cms-post.html#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <Icon name={'paper-plane-1'} class={'svg-icon-heavy'}/>
                            <span className="notification-badge notification-badge-number bg-primary">10</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end text-sm" aria-labelledby="messages">
                            <a className="dropdown-item d-flex align-items-center p-3" href="/cms-post.html#">
                                <img className="avatar avatar-sm p-1 me-2" src="../.." alt="Jason Doe" />
                                <div className="pt-1">
                                    <h6 className="fw-bold mb-0">Jason Doe</h6>
                                    <span className="text-muted text-sm">Sent you a message</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center p-3" href="/cms-post.html#">
                                <img className="avatar avatar-sm p-1 me-2" src="./temp2/avatar-1.jpg" alt="Frank Williams" />
                                    <div className="pt-1">
                                        <h6 className="fw-bold mb-0">Frank Williams</h6>
                                        <span className="text-muted text-sm">Sent you a message</span>
                                    </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center p-3" href="/cms-post.html#">
                                <img className="avatar avatar-sm p-1 me-2" src={Avatar} alt="Ashley Wood" />
                                <div className="pt-1">
                                    <h6 className="fw-bold mb-0">Ashley Wood</h6>
                                    <span className="text-muted text-sm">Sent you a message</span>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item text-center" href="/cms-post.html#">
                                <small className="fw-bold text-uppercase">View all messages </small>
                            </a>
                        </div>
                    </li>
                    <li className="nav-item dropdown ms-auto">
                        <a className="nav-link pe-0" id="userInfo" href="/cms-post.html#" data-bs-toggle="dropdown"  aria-expanded="false">
                            <img className="avatar p-1" src={Avatar} alt="Jason Doe"/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="userInfo">
                            <div className="dropdown-header text-gray-700">
                                <h6 className="text-uppercase font-weight-bold">Mark Stephen</h6>
                                <small>Web Developer</small>
                            </div>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/cms-post.html#">Settings</a>
                            <a className="dropdown-item" href="/cms-post.html#">Activity log </a>
                            <div className="dropdown-divider"></div>
                            <NavLink className="dropdown-item" onClick={logoutHandle} to={'/login'}>Logout</NavLink>
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    </>;
}

export default Header;