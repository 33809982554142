import React, {useEffect} from "react";
import Pagination from "../../common/pagination/pagination";
import PageTitle from "../../common/items/page-title";
import BulkActionModal from "../../common/modals/bulk-action-modal";
import BulkAction from "./_part/bulk-action";
import {useDispatch, useSelector} from "react-redux";
import Item from "./_part/item";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import {getLabelsPaginationSelector, getLabelsSelector} from "../../../store/selectors/labels-selectors";
import {getLabelsThunk, setCurrentPageThunk} from "../../../store/thunks/labels-thunk";
import {setCurrentPageAction} from "../../../store/actions/labels-action";
import LabelForm from "./label-form";

const Index = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const pagination = useSelector(getLabelsPaginationSelector);
    const onChangePagination = (data) => dispatch(setCurrentPageThunk(data.selected + 1));

    useEffect(() => {
        const params = qs.parse(location.search);
        const page = params.page ? params.page : 1;

        dispatch(setCurrentPageAction(page));
        dispatch(getLabelsThunk());
    }, [location.search]);

    const labels = useSelector(getLabelsSelector);
    return <>
        <div className="container-fluid px-lg-4 px-xl-5">
            <div className="row">
                <PageTitle title={'Labels'}/>

                <section className="mb-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                                <BulkAction/>

                                <div className="dataTable-container border-0">
                                    <table className="table table-hover align-middle dataTable-table" id="postDatatable">
                                        <thead>
                                            <tr>
                                            <th></th>
                                            <th>
                                                <span>id</span>
                                            </th>
                                            <th>
                                                <span>Name</span>
                                            </th>
                                            <th>
                                                <span>Color</span>
                                            </th>
                                            <th>
                                                <span>Created At</span>
                                            </th>
                                            <th>
                                                <span>Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                            { labels.map(item => (
                                                <Item key={item.id} item={item} />
                                            )) }
                                        </tbody>
                                    </table>
                                </div>

                                <Pagination
                                    currentPage={pagination.currentPage}
                                    pageCount={pagination.lastPage}
                                    perPage={pagination.perPage}
                                    total={pagination.total}
                                    handlePageClick={onChangePagination}
                                />

                            </div>
                        </div>
                    </div>
                </section>

                <LabelForm/>
                <BulkActionModal/>
            </div>
        </div>
    </>;
}

export default Index;