import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLeadThunk, setSelectedItemsThunk} from "../../../../store/thunks/leads-thunk";
import {getSelectedItemsSelector} from "../../../../store/selectors/leads-selectors";
import InfoPopover from "../../../common/items/info-popover";
import {LeadStatuses} from "../../../../store/constants/leads-constant";

const Item = ({ item }) => {

    const dispatch = useDispatch();
    const selectHandle = (id) => dispatch(setSelectedItemsThunk(id));
    const selectedIdx = useSelector(getSelectedItemsSelector);

    const handleLead = (id) => {
        dispatch(getLeadThunk(id))
    }

    return <>
        <tr>
            <td>
                <span className="form-check">
                  <input className="form-check-input"
                         type="checkbox"
                         onChange={() => selectHandle(item.id)}
                         value={item.id}
                         checked={selectedIdx.includes(item.id)}
                  />
                </span>
            </td>
            <td>
                {item.id}
            </td>
            <td>
                {item.name}
            </td>
            <td>{item.phone}</td>
            <td>{item.email}</td>
            <td>{item.country}</td>
            <td>{item.landing}</td>
            <td>{item.sid}</td>
            <td>
                {item.status}
                {
                    item.error &&
                    item.status !== LeadStatuses.success &&
                    <InfoPopover title={'Error!'} text={item.error}/>
                }
            </td>
            <td>{item.created_at}</td>
            <td>
                <i className="m-lg-1 fas fa-edit" onClick={() => handleLead(item.id)}></i>
            </td>
        </tr>
    </>;
}

export default Item;