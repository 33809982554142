import React from "react";
import TextField from "../../common/form/text-field";
import BaseModal from "../../common/modals/base-modal";
import {Formik, Form} from 'formik';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import {
    getIsShowEditModalSelector,
    getLeadSelector
} from "../../../store/selectors/leads-selectors";
import SelectField from "../../common/form/select-field";
import EditLeadValidation from "../../untils/validation/edit-lead-validation";
import {hideEditModalThunk, updateLeadThunk} from "../../../store/thunks/leads-thunk";
import {LeadSelectStatuses} from "../../../store/constants/leads-constant";

const EditLead = () => {

    const dispatch = useDispatch();
    const lead = useSelector(getLeadSelector);
    let isShow = useSelector(getIsShowEditModalSelector);

    const handleClose = () => {
        dispatch(hideEditModalThunk())
    }

    const onSubmit = (values) => {
        dispatch(updateLeadThunk(values));
    }

    const leadEditForm = () => {
        return <>
            <Formik initialValues={ {...lead} } validationSchema={EditLeadValidation} onSubmit={onSubmit}>
                {({values, setFieldValue, errors}) => (
                    <Form>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Id"  id="id" name="id" disabled/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Name"  id="name" name="name"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Surname"  id="surname" name="surname"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Phone"  id="phone" name="phone"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Email"  id="email" name="email"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Country"  id="country" name="country"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Landing"  id="landing" name="landing"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Url"  id="url" name="url"/>
                        </div>
                        <div className="mb-3">
                            <TextField type="text" placeholder="Sid"  id="sid" name="sid"/>
                        </div>
                        <div className="mb-3">
                            <SelectField type="text"
                                         placeholder="Status"
                                         id="status"
                                         name="status"
                                         valueParam="value"
                                         options={LeadSelectStatuses}
                            />
                        </div>
                        <Button type="submit" className={'form-control'} variant="primary">
                            Сохранить
                        </Button>
                    </Form>
                )}
            </Formik>
        </>;
    }

    return <>
        <BaseModal
            isShow={isShow}
            title={'Изменение лида'}
            component={leadEditForm}
            handleClose={handleClose}
        />
    </>;
}

export default EditLead;