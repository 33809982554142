import React from "react";
import {useDispatch} from "react-redux";
import {setBulkStatusThunk} from "../../../../store/thunks/sids-thunk";

const ChangeStatus = () => {
    const dispatch = useDispatch();
    const onChangeBulkStatus = (event) => dispatch(setBulkStatusThunk(parseInt(event.target.value)));

    return <>
        <div className="mb-4">
            <label className="form-label" htmlFor="change-status">Status</label>
            <select className="form-select" defaultValue={'default'} id="change-status" onChange={onChangeBulkStatus}>
                <option value="default" disabled>Выберите статус</option>
                <option value={0}>Выкл.</option>
                <option value={1}>Вкл.</option>
            </select>
            <div className="form-text">Выберите статус из списка для выбранных лидов</div>
        </div>
    </>;
}

export default ChangeStatus;